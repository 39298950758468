import { pairNames, PairNameToPairId } from '@/store/pairs';
import pairsdata from '../../../pairData.json';

const preLaunchPairs = [PairNameToPairId['BLAST/USD']];
const delistedPairs = [PairNameToPairId['FET/USD'], PairNameToPairId['PRIME/USD']];

export interface IPair {
  feeIndex: number;
  feed: {
    id: string;
    maxDeviationP: string;
  };
  from: string;
  groupIndex: number;
  isDelisted: boolean;
  maxWalletOI: number;
  to: string;
  _index: number;
}

export type IndexablePairs = {
  [x: number]: IPair;
};

const usePairs = () => {
  const filteredPairs = pairsdata.pairs
    .filter((pair) => ![...preLaunchPairs, ...delistedPairs].includes(pair._index))
    .filter((pair) => !pair.isDelisted)
    .filter((pair) => pairNames[`${pair.from}/${pair.to}`]);

  const pairsObj: IndexablePairs = filteredPairs.reduce((acc, pair) => {
    return {
      ...acc,
      [pair._index]: pair,
    };
  }, {});

  const pairsObjAll: IndexablePairs = pairsdata.pairs.reduce((acc, pair) => {
    return {
      ...acc,
      [pair._index]: pair,
    };
  }, {});

  return {
    pairs: filteredPairs || [],
    pairsObj: pairsObj || {},
    pairsCount: filteredPairs.length || 0,
    maxTradesPerPair: pairsdata?.maxTradesPerPair || 0,
    pairsObjAll,
  };
};

export default usePairs;
