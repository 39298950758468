import { formatUnits } from 'viem';
import { DateTime, IANAZone } from 'luxon';
import { roundDownTrim } from './numbers';

const AERO_SKEW_FEES = [
  [-20, 2400],
  [-20, 2400],
  [-20, 2400],
  [-20, 2400],
  [0, 1600],
  [0, 1600],
  [-20, 2800],
  [-20, 2800],
  [-20, 2800],
  [-20, 2800],
];

const GOLD_SKEW_FEES = [
  [0, 600],
  [0, 600],
  [0, 600],
  [0, 600],
  [0, 600],
  [0, 600],
  [0, 600],
  [0, 600],
  [0, 600],
  [0, 600],
];

const SILVER_SKEW_FEES = [
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
];

const MAJORS_SKEW_FEES = [
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
  [0, 800],
];

const DEFAULT_SKEW_FEES = [
  [-10, 1200],
  [-10, 1200],
  [-10, 1200],
  [-10, 1200],
  [0, 800],
  [0, 800],
  [-10, 1400],
  [-10, 1400],
  [-10, 1400],
  [-10, 1400],
];

const FOREX_SKEW_FEES = [
  [-5, 500],
  [-5, 500],
  [-5, 500],
  [-5, 500],
  [0, 300],
  [0, 300],
  [-5, 600],
  [-5, 600],
  [-5, 600],
  [-5, 600],
];

export const getSkewFee = ({
  pairIndex,
  groupIndex,
  pctIndex,
}: {
  pairIndex: number;
  groupIndex: number;
  pctIndex: number;
}): {
  skewFeeParam1: number;
  skewFeeParam2: number;
} => {
  // MAJORS
  if ([0, 1, 2].includes(pairIndex)) {
    return {
      skewFeeParam1: MAJORS_SKEW_FEES[pctIndex]?.[0],
      skewFeeParam2: MAJORS_SKEW_FEES[pctIndex]?.[1],
    };
  }
  // AERO
  if (pairIndex === 37) {
    return {
      skewFeeParam1: AERO_SKEW_FEES[pctIndex]?.[0],
      skewFeeParam2: AERO_SKEW_FEES[pctIndex]?.[1],
    };
  }

  // FOREX
  if (groupIndex === 2) {
    return {
      skewFeeParam1: FOREX_SKEW_FEES[pctIndex]?.[0],
      skewFeeParam2: FOREX_SKEW_FEES[pctIndex]?.[1],
    };
  }

  // SILVER
  if (pairIndex === 20) {
    return {
      skewFeeParam1: SILVER_SKEW_FEES[pctIndex]?.[0],
      skewFeeParam2: SILVER_SKEW_FEES[pctIndex]?.[1],
    };
  }
  // GOLD
  if (pairIndex === 21) {
    return {
      skewFeeParam1: GOLD_SKEW_FEES[pctIndex]?.[0],
      skewFeeParam2: GOLD_SKEW_FEES[pctIndex]?.[1],
    };
  }

  // FOR THE REST
  return {
    skewFeeParam1: DEFAULT_SKEW_FEES[pctIndex]?.[0],
    skewFeeParam2: DEFAULT_SKEW_FEES[pctIndex]?.[1],
  };
};

export const Float18 = (o: bigint, decimals = 2, round = 'basic') => {
  if (round == 'trim') {
    return roundDownTrim(parseFloat(formatUnits(o, 18)), decimals);
  }
  return Math.round(parseFloat(formatUnits(o, 18)) * 10 ** decimals) / 10 ** decimals;
};

const FOREX_MARKETS_TIME_ZONE_IANA = IANAZone.create('America/New_York');

export const isForexOpen = (dateToCheck: Date) => {
  const now = DateTime.fromJSDate(dateToCheck).setZone(FOREX_MARKETS_TIME_ZONE_IANA);
  const weekday = now.weekday;
  const hour = now.hour;
  const dayOfMonth = now.day;
  const month = now.month;
  const isInDST = now.isInDST;

  const isClosed =
    // Christmas 2023
    (month === 12 && dayOfMonth >= 25 && dayOfMonth <= 27) ||
    // New Year's Eve 2023
    (month === 1 && dayOfMonth >= 1 && dayOfMonth <= 2) ||
    // Friday after 4PM (DST) and 5PM (non-DST)
    (weekday === 5 && ((isInDST && hour >= 16) || hour >= 17)) ||
    // Saturday
    weekday === 6 ||
    // Sunday before 4PM (DST) and 5PM (non-DST)
    (weekday === 7 && ((isInDST && hour < 16) || hour < 17));

  return !isClosed;
};

export const getLastOpenDate = (currentDate: Date) => {
  let dateToCheck = DateTime.fromJSDate(currentDate);

  while (!isForexOpen(dateToCheck.toJSDate())) {
    dateToCheck = dateToCheck.minus({ days: 1 });
  }

  return dateToCheck.toJSDate();
};

export function getMultiplierForPair(from: string, groupIndex: number) {
  const result = {
    multiplier: 10 ** 2,
    decimals: 2,
  };

  if (groupIndex === 2) {
    return {
      multiplier: 10 ** 5,
      decimals: 5,
    };
  }

  if (groupIndex === 3) {
    return {
      multiplier: 10 ** 3,
      decimals: 3,
    };
  }

  if (groupIndex === 1) {
    if (['DOGE', 'MATIC', 'SEI'].includes(from)) {
      return {
        multiplier: 10 ** 4,
        decimals: 4,
      };
    }

    if (['ARB', 'OP'].includes(from)) {
      return {
        multiplier: 10 ** 3,
        decimals: 3,
      };
    }
  }

  if (groupIndex === 4) {
    if (['PEPE', 'BONK', 'SHIB'].includes(from)) {
      return {
        multiplier: 10 ** 10,
        decimals: 10,
      };
    }
  }

  if (['ENA', 'REZ'].includes(from)) {
    return {
      multiplier: 10 ** 4,
      decimals: 4,
    };
  }

  if (
    [
      'WIF',
      'RENDER',
      'WLD',
      'FET',
      'ARKM',
      'PENDLE',
      'ONDO',
      'PRIME',
      'DYM',
      'JUP',
      'ETHFI',
      'AERO',
      'STX',
      'NEAR',
      'LDO',
    ].includes(from)
  ) {
    return {
      multiplier: 10 ** 3,
      decimals: 3,
    };
  }

  return result;
}

export function getSkewLossProtectionP(skew: number, selectedPairIndex: number) {
  if ([0, 1].includes(selectedPairIndex)) {
    if (skew >= 0 && skew < 52) {
      return 0;
    }
    return 20;
  }
  if ([2].includes(selectedPairIndex)) {
    if (skew >= 0 && skew < 52) {
      return 0;
    }
    return 10;
  }
  if (skew >= 0 && skew < 55) {
    return 0;
  }
  if (skew >= 55 && skew < 70) {
    return 10;
  }
  if (skew >= 70 && skew < 80) {
    return 10;
  }
  if (skew >= 80 && skew <= 100) {
    return 10;
  }

  return 0;
}
