/* eslint-disable import/no-anonymous-default-export */
type Indexable = {
  [x: string]: {
    id: string;
    group: string;
    isReversed?: boolean;
  };
};

export const pythFeedMapping: Indexable = {
  'AERO/USD': {
    id: '0x9db37f4d5654aad3e37e2e14ffd8d53265fb3026d1d8f91146539eebaa2ef45f',
    group: 'crypto',
  },
  'ETHFI/USD': {
    id: '0xb27578a9654246cb0a2950842b92330e9ace141c52b63829cc72d5c45a5a595a',
    group: 'crypto',
  },
  'JUP/USD': {
    id: '0x0a0408d619e9380abad35060f9192039ed5042fa6f82301d0e48bb52be830996',
    group: 'crypto',
  },
  'REZ/USD': {
    id: '0x9df307038f76e26ba0f9aaa1d5eefce919bf5b7b282d0ad247d4f77ffb506ede',
    group: 'crypto',
  },
  'RENDER/USD': {
    id: '0x3d4a2bd9535be6ce8059d75eadeba507b043257321aa544717c56fa19b49e35d',
    group: 'crypto',
  },
  'WLD/USD': {
    id: '0xd6835ad1f773de4a378115eb6824bd0c0e42d84d1c84d9750e853fb6b6c7794a',
    group: 'crypto',
  },
  'ARKM/USD': {
    id: '0x7677dd124dee46cfcd46ff03cf405fb0ed94b1f49efbea3444aadbda939a7ad3',
    group: 'crypto',
  },
  'PENDLE/USD': {
    id: '0x9a4df90b25497f66b1afb012467e316e801ca3d839456db028892fe8c70c8016',
    group: 'crypto',
  },
  'ONDO/USD': {
    id: '0xd40472610abe56d36d065a0cf889fc8f1dd9f3b7f2a478231a5fc6df07ea5ce3',
    group: 'crypto',
  },
  'PRIME/USD': {
    id: '0xe417fb7d1edcfe70283c608fa9f14d11ebf4d1b3ecf2e97e42a110f7fb649843',
    group: 'crypto',
  },
  'DYM/USD': {
    id: '0xa9f3b2a89c6f85a6c20a9518abde39b944e839ca49a0c92307c65974d3f14a57',
    group: 'crypto',
  },
  'ORDI/USD': {
    id: '0x193c739db502aadcef37c2589738b1e37bdb257d58cf1ab3c7ebc8e6df4e3ec0',
    group: 'crypto',
  },
  'STX/USD': {
    id: '0xec7a775f46379b5e943c3526b1c8d54cd49749176b0b98e02dde68d1bd335c17',
    group: 'crypto',
  },
  'ENA/USD': {
    id: '0xb7910ba7322db020416fcac28b48c01212fd9cc8fbcbaf7d30477ed8605f6bd4',
    group: 'crypto',
  },
  'TIA/USD': {
    id: '0x09f7c1d7dfbb7df2b8fe3d3d87ee94a2259d212da4f30c1f0540d066dfa44723',
    group: 'crypto',
  },
  'SEI/USD': {
    id: '0x53614f1cb0c031d4af66c04cb9c756234adad0e1cee85303795091499a4084eb',
    group: 'crypto',
  },
  'ARB/USD': {
    id: '0x3fa4252848f9f0a1480be62745a4629d9eb1322aebab8a791e344b3b9c1adcf5',
    group: 'crypto',
  },
  'AVAX/USD': {
    id: '0x93da3352f9f1d105fdfe4971cfa80e9dd777bfc5d0f683ebb6e1294b92137bb7',
    group: 'crypto',
  },
  'BNB/USD': {
    id: '0x2f95862b045670cd22bee3114c39763a4a08beeb663b145d283c31d7d1101c4f',
    group: 'crypto',
  },
  'BONK/USD': {
    id: '0x72b021217ca3fe68922a19aaf990109cb9d84e9ad004b4d2025ad6f529314419',
    group: 'crypto',
  },
  'BTC/USD': {
    id: '0xe62df6c8b4a85fe1a67db44dc12de5db330f7ac66b72dc658afedf0f4a415b43',
    group: 'crypto',
  },
  'DOGE/USD': {
    id: '0xdcef50dd0a4cd2dcc17e45df1676dcb336a11a61c69df7a0299b0150c672d25c',
    group: 'crypto',
  },
  'ETH/USD': {
    id: '0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace',
    group: 'crypto',
  },
  'FET/USD': {
    id: '0xb98e7ae8af2d298d2651eb21ab5b8b5738212e13efb43bd0dfbce7a74ba4b5d0',
    group: 'crypto',
  },
  'LDO/USD': {
    id: '0xc63e2a7f37a04e5e614c07238bedb25dcc38927fba8fe890597a593c0b2fa4ad',
    group: 'crypto',
  },
  'LINK/USD': {
    id: '0x8ac0c70fff57e9aefdf5edf44b51d62c2d433653cbb2cf5cc06bb115af04d221',
    group: 'crypto',
  },
  'MATIC/USD': {
    id: '0xffd11c5a1cfd42f80afb2df4d9f264c15f956d68153335374ec10722edd70472',
    group: 'crypto',
  },
  'NEAR/USD': {
    id: '0xc415de8d2eba7db216527dff4b60e8f3a5311c740dadb233e13e12547e226750',
    group: 'crypto',
  },
  'OP/USD': {
    id: '0x385f64d993f7b77d8182ed5003d97c60aa3361f3cecfe711544d2d59165e9bdf',
    group: 'crypto',
  },
  'SHIB/USD': {
    id: '0xf0d57deca57b3da2fe63a493f4c25925fdfd8edf834b20f93e1f84dbd1504d4a',
    group: 'crypto',
  },
  'SOL/USD': {
    id: '0xef0d8b6fda2ceba41da15d4095d1da392a0d2f8ed0c6c7bc0f4cfac8c280b56d',
    group: 'crypto',
  },
  'WIF/USD': {
    id: '0x4ca4beeca86f0d164160323817a4e42b10010a724c2217c6ee41b54cd4cc61fc',
    group: 'crypto',
  },
  'PEPE/USD': {
    id: '0xd69731a2e74ac1ce884fc3890f7ee324b6deb66147055249568869ed700882e4',
    group: 'crypto',
  },
  'AUD/USD': {
    id: '0x67a6f93030420c1c9e3fe37c1ab6b77966af82f995944a9fefce357a22854a80',
    group: 'forex',
  },
  'EUR/USD': {
    id: '0xa995d00bb36a63cef7fd2c287dc105fc8f3d93779f062f09551b0af3e81ec30b',
    group: 'forex',
  },
  'GBP/USD': {
    id: '0x84c2dde9633d93d1bcad84e7dc41c9d56578b7ec52fabedc1f335d673df0a7c1',
    group: 'forex',
  },
  'NZD/USD': {
    id: '0x92eea8ba1b00078cdc2ef6f64f091f262e8c7d0576ee4677572f314ebfafa4c7',
    group: 'forex',
  },
  'USD/CAD': {
    id: '0x3112b03a41c910ed446852aacf67118cb1bec67b2cd0b9a214c58cc0eaa2ecca',
    group: 'forex',
  },
  'USD/CHF': {
    id: '0x0b1e3297e69f162877b577b0d6a47a0d63b2392bc8499e6540da4187a63e28f8',
    group: 'forex',
  },
  'USD/JPY': {
    id: '0xef2c98c804ba503c6a707e38be4dfbb16683775f195b091252bf24693042fd52',
    group: 'forex',
    // isReversed: true,
  },
  'USD/SEK': {
    id: '0x8ccb376aa871517e807358d4e3cf0bc7fe4950474dbe6c9ffc21ef64e43fc676',
    group: 'forex',
  },
  'USD/SGD': {
    id: '0x396a969a9c1480fa15ed50bc59149e2c0075a72fe8f458ed941ddec48bdb4918',
    group: 'forex',
  },
  'XAG/USD': {
    id: '0xf2fb02c32b055c805e7238d628e5e9dadef274376114eb1f012337cabe93871e',
    group: 'metal',
  },
  'XAU/USD': {
    id: '0x765d2ba906dbc32ca17cc11f5310a89e9ee1f6420508c63861f2f8ba4ee34bb2',
    group: 'metal',
  },
  'INJ/USD': {
    id: '0x7a5bc1d2b56ad029048cd63964b3ad2776eadf812edc1a43a31406cb54bff592',
    group: 'crypto',
  },
  'ZK/USD': {
    id: '0xcc03dc09298fb447e0bf9afdb760d5b24340fd2167fd33d8967dd8f9a141a2e8',
    group: 'crypto',
  },
  'ZRO/USD': {
    id: '0x3bd860bea28bf982fa06bcf358118064bb114086cc03993bd76197eaab0b8018',
    group: 'crypto',
  },
  'BLAST/USD': {
    id: '0x057345a7e9ef0f36dca8ad1c4e5788808b85f3084cc7b0d8cb29ac5012d88f0d',
    group: 'crypto',
  },
  'AAVE/USD': {
    id: '0x2b9ab1e972a281585084148ba1389800799bd4be63b957507db1349314e47445',
    group: 'crypto',
  },
  'SUI/USD': {
    id: '0x23d7315113f5b1d3ba7a83604c44b94d79f4fd69af77f804fc7f920a6dc65744',
    group: 'crypto',
  },
  'TAO/USD': {
    id: '0x410f41de235f2db824e562ea7ab2d3d3d4ff048316c61d629c0b93f58584e1af',
    group: 'crypto',
  },
  'EIGEN/USD': {
    id: '0xc65db025687356496e8653d0d6608eec64ce2d96e2e28c530e574f0e4f712380',
    group: 'crypto',
  },
};

export const pythFeedMapByFeedId: Record<
  string,
  { pair: string; id: string; group: string; isReversed?: boolean }
> = Object.keys(pythFeedMapping).reduce(
  (acc, curr) => ({
    ...acc,
    [pythFeedMapping[curr].id.toLowerCase()]: {
      pair: curr,
      ...pythFeedMapping[curr],
    },
  }),
  {}
);

export default pythFeedMapping;
