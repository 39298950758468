import { useEffect, useCallback, useRef, useMemo } from 'react';
import usePairs from '../pairs/usePairs';
import { PurePriceType, usePairStore } from '@/store/pairs';
import { roundDownTrim } from '@/lib/numbers';

export interface PriceData {
  [priceId: string]: {
    price: number;
    timestamp: number;
  };
}

type PriceFeedData = {
  emaPrice: {
    conf: string;
    expo: number;
    price: string;
    publishTime: number;
  };
  id: string;
  price: {
    conf: string;
    expo: number;
    price: string;
    publishTime: number;
  };
};

let tempPriceStore: Record<number, PurePriceType> = {};

const usePythPrices = () => {
  const workerRef = useRef<Worker | null>(null);
  const { pairs } = usePairs();

  const updatePairCurrentPricePureInBatch = usePairStore(
    (state) => state.updatePairCurrentPricePureInBatch
  );

  const pairsFeedIds = useMemo(() => pairs.map((pair) => pair.feed.id), [pairs]);

  const initializeWorker = useCallback(() => {
    workerRef.current = new Worker(new URL('../../workerScripts/priceWorker.ts', import.meta.url), {
      type: 'module',
    });
    workerRef.current.postMessage({ type: 'INIT', payload: { feedIds: pairsFeedIds } });

    workerRef.current.onmessage = (event: MessageEvent) => {
      const { type, payload } = event.data;
      if (type === 'PRICE_UPDATE') {
        const data = payload as PriceFeedData;
        let id = data.id;
        const price = data.price;
        let computedPrice = Number(price.price) * Number(10 ** price.expo);

        const timestamp = price.publishTime;

        if (!id.startsWith('0x')) {
          id = '0x' + id;
        }

        const feedIndex = pairsFeedIds.indexOf(id.toLowerCase());

        if (feedIndex === -1) {
          return;
        }

        const pair = pairs[feedIndex];

        computedPrice = roundDownTrim(computedPrice);

        tempPriceStore[pair._index] = {
          price: computedPrice,
          timestamp,
        };
      }
    };
  }, []);

  useEffect(() => {
    initializeWorker();

    return () => {
      if (workerRef.current) {
        workerRef.current.postMessage({ type: 'TERMINATE' });
      }
    };
  }, [initializeWorker]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Object.keys(tempPriceStore).length > 0) {
        updatePairCurrentPricePureInBatch(tempPriceStore);
        tempPriceStore = {};
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
};

export default usePythPrices;
