import { useQuery } from '@tanstack/react-query';
import usePairs from '../pairs/usePairs';
import { getLastOpenDate } from '@/lib/contracts';
import { pythFeedMapByFeedId } from '@/constants/pyth';
import pairsdata from '../../../pairData.json';
import { usePairStore } from '@/store/pairs';
import { useMemo } from 'react';
import { FEED_URL } from '@/lib/env';

export interface IndexablePriceDelta {
  [priceId: string]: number;
}

type Pair = (typeof pairsdata)['pairs'][0];

const groupEnum = ['Crypto', 'Crypto', 'FX', 'Metal', 'Crypto', 'Crypto'];

const fetchPairHistory = async (pair: Pair) => {
  let currentDate = Math.round(Date.now() / 1000);

  if (pair?.groupIndex === 2 || pair?.groupIndex === 3) {
    currentDate = Math.round(getLastOpenDate(new Date()).getTime() / 1000);
  }

  const pairString = pythFeedMapByFeedId[pair.feed.id]?.isReversed
    ? `${pair?.to}%2F${pair?.from}`
    : `${pair?.from}%2F${pair?.to}`;

  let timeFrom = currentDate - 24 * 60 * 60;

  const symbolInfo = { from: pair?.from, to: pair?.to };
  const query = `?from=${timeFrom}&to=${currentDate}&resolution=1&symbol=${
    groupEnum[pair?.groupIndex]
  }.${pairString}&symbolInfo=${JSON.stringify(symbolInfo)}&trim=true`;

  const url = `${FEED_URL}/v2/history/${groupEnum[pair?.groupIndex]}/${pair?.from}/${pair?.to}/D/${
    timeFrom
  }/${currentDate}`;

  try {
    const res = await fetch(url);
    if (res.status === 200) {
      const result = await res.json();
      return result.length > 0 ? result[result.length - 1].o : 0;
    }
    throw new Error('Primary fetch failed');
  } catch (error) {
    console.log(error);
    const fallbackUrl = `https://benchmarks.pyth.network/v1/shims/tradingview/history${query}`;
    const fallbackRes = await fetch(fallbackUrl);
    if (fallbackRes.status === 200) {
      const result = await fallbackRes.json();
      return result?.o?.length > 0 ? result.o[0] : 0;
    }
    throw new Error('Both fetches failed');
  }
};

const useLastDayPythPrice = () => {
  const { pairs } = usePairs();
  const prices = usePairStore((state) => state.pairCurrentPricePure);

  const response = useQuery({
    queryKey: ['pairHistory'],
    queryFn: async () => {
      const historyData = await Promise.all(pairs.map((pair) => fetchPairHistory(pair)));

      return historyData.reduce((acc, data, index) => {
        acc[pairs[index]._index] = data;
        return acc;
      }, {});
    },
    enabled: pairs.length > 0,
  });

  const pricesDelta: IndexablePriceDelta = useMemo(
    () =>
      response && response.data
        ? pairs.reduce((acc, pair) => {
            const currentPairLastDayPrice = response.data[pair._index] || null;
            const currentPairPrice = prices[pair._index]?.price || null;
            const priceDelta =
              currentPairLastDayPrice && currentPairPrice
                ? ((currentPairPrice - currentPairLastDayPrice) / currentPairLastDayPrice) * 100
                : null;
            return {
              ...acc,
              [pair._index]: priceDelta,
            };
          }, {})
        : {},
    [pairs, prices, response]
  );

  return { lastDayPrice: response, priceDelta: pricesDelta };
};

export default useLastDayPythPrice;
