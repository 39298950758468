import { create } from 'zustand';
import { GroupInfoType, PairInfoType } from '@/services/trade/types';
import merge from 'lodash.merge';

export type PurePriceType = {
  price: number;
  timestamp: number;
};

export enum PairNameToPairId {
  'ETH/USD' = 0,
  'BTC/USD' = 1,
  'SOL/USD' = 2,
  'BNB/USD' = 3,
  'ARB/USD' = 4,
  'DOGE/USD' = 5,
  'AVAX/USD' = 6,
  'OP/USD' = 7,
  'POL/USD' = 8,
  'TIA/USD' = 9,
  'SEI/USD' = 10,
  'EUR/USD' = 11,
  'JPY/USD' = 12,
  'GBP/USD' = 13,
  'CAD/USD' = 14,
  'CHF/USD' = 15,
  'SEK/USD' = 16,
  'AUD/USD' = 17,
  'NZD/USD' = 18,
  'SGD/USD' = 19,
  'XAG/USD' = 20,
  'XAU/USD' = 21,
  'SHIB/USD' = 22,
  'PEPE/USD' = 23,
  'BONK/USD' = 24,
  'WIF/USD' = 25,
  'RENDER/USD' = 26,
  'WLD/USD' = 27,
  'FET/USD' = 28,
  'ARKM/USD' = 29,
  'PENDLE/USD' = 30,
  'ONDO/USD' = 31,
  'PRIME/USD' = 32,
  'DYM/USD' = 33,
  'ORDI/USD' = 34,
  'STX/USD' = 35,
  'ENA/USD' = 36,
  'AERO/USD' = 37,
  'ETHFI/USD' = 38,
  'JUP/USD' = 39,
  'REZ/USD' = 40,
  'LINK/USD' = 41,
  'LDO/USD' = 42,
  'NEAR/USD' = 43,
  'INJ/USD' = 44,
  'ZK/USD' = 45,
  'ZRO/USD' = 46,
  'BLAST/USD' = 47,
  'AAVE/USD' = 48,
  'SUI/USD' = 49,
  'TAO/USD' = 50,
  'EIGEN/USD' = 51,
}

export const pairNames: {
  [key: string]: string;
} = {
  'BTC/USD': 'Bitcoin',
  'ETH/USD': 'Ethereum',
  'SOL/USD': 'Solana',
  'AVAX/USD': 'Avalanche',
  'BNB/USD': 'Binance Smart Chain',
  'ARB/USD': 'Arbitrum',
  'OP/USD': 'Optimism',
  'POL/USD': 'Polygon',
  'DOGE/USD': 'Dogecoin',
  'TIA/USD': 'Celestia',
  'SHIB/USD': 'Shiba Inu',
  'BONK/USD': 'Bonk',
  'PEPE/USD': 'Pepe',
  'WIF/USD': 'dogwifhat',
  'SEI/USD': 'Sei',
  'RENDER/USD': 'Render',
  'WLD/USD': 'Worldcoin',
  'ARKM/USD': 'Arkham',
  'PENDLE/USD': 'Pendle',
  'ONDO/USD': 'Ondo',
  'FET/USD': 'Fetch.ai',
  'PRIME/USD': 'Echelon Prime',
  'DYM/USD': 'Dymension',
  'ORDI/USD': 'Ordi',
  'STX/USD': 'Stacks',
  'ENA/USD': 'Ethena',
  'EUR/USD': 'Euro / US dollar',
  'GBP/USD': 'British pound / US dollar',
  'USD/JPY': 'US dollar / Japanese yen',
  'USD/CAD': 'US dollar / CA dollar',
  'USD/CHF': 'US dollar / Swiss franc',
  'USD/SEK': 'US dollar / SE Krona',
  'AUD/USD': 'AU dollar / US dollar',
  'NZD/USD': 'NZ dollar / US dollar',
  'USD/SGD': 'US dollar / SG dollar',
  'XAG/USD': 'Silver / US dollar',
  'XAU/USD': 'Gold / US dollar',
  'AERO/USD': 'Aerodrome',
  'ETHFI/USD': 'Ether.fi',
  'JUP/USD': 'Jupiter',
  'REZ/USD': 'Renzo',
  'LINK/USD': 'Chainlink',
  'NEAR/USD': 'Near',
  'LDO/USD': 'Lido Finance',
  'AAVE/USD': 'Aave',
  'SUI/USD': 'Sui',
  'BLAST/USD': 'Blast',
  'ZK/USD': 'ZKsync',
  'ZRO/USD': 'Layer Zero',
  'TAO/USD': 'Tao Bittensor',
  'EIGEN/USD': 'Eigen Layer',
  'INJ/USD': 'Injective',
};

type State = {
  pairCurrentPricePure: { [x: number]: PurePriceType };
  pairCurrentPricePureDelta: string[];
  pairMarketExecutionPrice: number;

  groupInfo: { [key: string]: GroupInfoType };
  pairInfos: { [key: string]: PairInfoType };
  pairCount: number;
  maxTradesPerPair: number;
  totalOi: number;
  maxOpenInterest: number;
};

const initialState = {
  pairCurrentPricePure: {},
  pairCurrentPricePureDelta: [],
  pairMarketExecutionPrice: 0,

  groupInfo: {},
  pairInfos: {},
  pairCount: 0,
  maxTradesPerPair: 0,
  totalOi: 0,
  maxOpenInterest: 0,
};

type Action = {
  updatePairCurrentPricePure: (index: number, priceData: PurePriceType) => void;
  updatePairCurrentPricePureInBatch: (data: Record<number, PurePriceType>) => void;
  setInitialData: (data: Partial<State>) => void;
  updateGroupInfos: (groupInfos: { [key: string]: Partial<GroupInfoType> }) => void;
  updatePairInfos: (pairInfos: { [key: string]: Partial<PairInfoType> }) => void;
  updatePairStore: (key: keyof State, value: any) => void;
  resetStore: () => void;
};

export const usePairStore = create<State & Action>((set) => ({
  ...initialState,
  updatePairCurrentPricePure: (index, priceData) =>
    set((state) => {
      const pairCurrentPricePure = { ...state.pairCurrentPricePure, [index]: priceData }; // copy the array
      return { pairCurrentPricePure };
    }),
  updatePairCurrentPricePureInBatch: (data) =>
    set((state) => {
      const pairCurrentPricePure = { ...state.pairCurrentPricePure, ...data }; // copy the array
      return { pairCurrentPricePure };
    }),
  setInitialData: (data) =>
    set((state) => ({
      ...state,
      ...data,
    })),
  updateGroupInfos: (groupInfosUpdate) =>
    set((state) => ({
      groupInfo: {
        ...state.groupInfo,
        ...Object.keys(groupInfosUpdate).reduce(
          (updatedGroupInfo: { [x: string]: any }, groupId) => {
            updatedGroupInfo[groupId] = merge(
              {},
              state.groupInfo[groupId] || {},
              groupInfosUpdate[groupId]
            );
            return updatedGroupInfo;
          },
          {}
        ),
      },
    })),
  updatePairInfos: (pairInfosUpdate) =>
    set((state) => ({
      pairInfos: {
        ...state.pairInfos,
        ...Object.keys(pairInfosUpdate).reduce((updatedPairInfos: { [x: string]: any }, pairId) => {
          updatedPairInfos[pairId] = merge(
            {},
            state.pairInfos[pairId] || {},
            pairInfosUpdate[pairId]
          );
          return updatedPairInfos;
        }, {}),
      },
    })),
  updatePairStore: (key, value) => set(() => ({ [key]: value })),
  resetStore: () => set(() => initialState),
}));
