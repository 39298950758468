import { formatUnits, parseUnits } from 'viem';

const getDecimalPlaces = (value: number): number => {
  if (value < 0.0001) return 10;
  if (value < 0.001) return 8;
  if (value < 0.01) return 5;
  if (value < 9) return 4;
  return 2;
};

export function roundDownTrim(num: number, userDecimalPlaces?: number): number {
  if (num === 0 || !num) return 0.0;

  const decimalPlaces =
    userDecimalPlaces !== undefined ? userDecimalPlaces : getDecimalPlaces(Math.abs(num));

  const factor = Math.pow(10, decimalPlaces);
  let rounded: number;

  if (num >= 0) {
    rounded = Math.floor(num * factor) / factor;
  } else {
    rounded = Math.ceil(num * factor) / factor;
  }

  // Convert to string with fixed decimal places
  const fixedString = rounded.toFixed(decimalPlaces);

  // Remove trailing zeros and decimal point if necessary
  const trimmedString = fixedString.replace(/\.?0+$/, '');

  // Parse back to float
  return parseFloat(trimmedString);
}

export const Float6 = (o: bigint, decimals = 2, round = 'basic') => {
  if (round == 'trim') {
    return roundDownTrim(parseFloat(formatUnits(o, 6)), decimals);
  }
  return Math.round(parseFloat(formatUnits(o, 6)) * 10 ** decimals) / 10 ** decimals;
};

export const Float6Raw = (o: bigint) => parseFloat(formatUnits(o, 6));

export const safeParseUnits = (s: string, decimals = 18) => {
  let value = s;

  if (value.includes('e')) {
    value = Number(s).toLocaleString('fullwide', { useGrouping: false });
  }

  if (value.includes('Infinity')) {
    value = '0';
  }

  return parseUnits(value, decimals);
};

export const validateNumericInput = (input: string): boolean => {
  if (input.trim() === '') {
    return true;
  }

  if (input === '.') return true;

  // Regular expression to match a number with up to 6 decimal places
  const regex = /^-?\d*(\.\d{0,6})?$/;

  // Check if the input matches the regex
  if (!regex.test(input)) {
    return false;
  }

  // Attempt to parse the input as a float
  const parsedNumber = parseFloat(input);

  // Check if it's a valid number
  return !isNaN(parsedNumber);
};

export const sanitizeInputs = (val: string): string => {
  if (!val) return '';

  if (val === '.') return '0.';

  // remove non-numeric characters
  val = val.replace(/[^0-9\.]/g, '');

  // remove leading zeros
  val = val.replace(/^0+(?=[0-9])/, '');

  const parts = val.toString().split('.');
  let str = '';
  for (let i = 0; i < parts.length; i++) {
    if (i > 0) {
      str += parts[i];
    }
  }
  if (parts.length > 1) str = `${parts[0]}.${str}`;
  else str = parts[0];
  return str;
};
