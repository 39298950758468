import { API_URL } from './env';
import { captureError } from './sentry';

export const requestApp = async <T>({
  endpoint,
  method = 'GET',
  data = {},
  baseURL = false,
  authHeader = undefined,
  version = 'v1',
}: {
  endpoint: string;
  method?: 'GET' | 'POST';
  data?: any;
  baseURL?: boolean;
  authHeader?: string;
  version?: 'v1' | 'v2';
}): Promise<T> => {
  return new Promise(async (resolve, reject) => {
    const options = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With',
      },
    };
    if (authHeader) {
      // @ts-ignore
      options.headers.authorization = authHeader;
    }

    if (method == 'POST') {
      // @ts-ignore
      options.method = 'POST';
      // @ts-ignore
      options.body = JSON.stringify(data);
    }

    const BASE_URL = baseURL ? baseURL : API_URL;

    try {
      let response = await fetch(BASE_URL + '/' + version + endpoint, options);

      response = await response.json();

      // @ts-ignore
      if (!response.success) {
        captureError(response, { action: 'requestApp', endpoint, method, data });
        reject({
          // @ts-ignore
          errorMessage: response.errorMessage,
          response,
          isError: false,
        });
      } else {
        // @ts-ignore
        resolve(response);
      }
    } catch (error) {
      captureError(error, { action: 'requestApp', endpoint, method, data });
      reject({
        // @ts-ignore
        errorMessage:
          (error as any).responseJSON?.errorMessage ||
          'An unknown error occurred. Try again later.',
        error,
        isError: true,
      });
    }
  });
};
